import React, { useState } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { useTranslation } from '../../services/translation'

const PassWithEye = ({ formik, confirmPass, className }) => {
  const { t } = useTranslation()
  const [passIcon, setPassIcon] = useState('fa-eye')
  const [passInput, setPassInput] = useState('password')

  const changePassView = () => {
    passIcon === 'fa-eye' ? setPassIcon('fa-eye-slash') : setPassIcon('fa-eye')
    passInput === 'password' ? setPassInput('text') : setPassInput('password')
  }

  return !confirmPass ? (
    <InputGroup className={className}>
      <Form.Control
        {...formik.getFieldProps('password')}
        type={passInput}
        placeholder={t('forms.senha')}
        isInvalid={formik.touched.password && formik.errors.password}
        isValid={formik.touched.password && !formik.errors.password}
        autoComplete='new-password'
      />
      <InputGroup.Text>
        <a onClick={() => changePassView()}>
          <i className={'fa ' + passIcon} />
        </a>
      </InputGroup.Text>

      <Form.Control.Feedback type='invalid'>
        {formik.touched.password && formik.errors.password
          ? formik.errors.password
          : null}
      </Form.Control.Feedback>
    </InputGroup>
  ) : (
    <InputGroup className={className}>
      <Form.Control
        {...formik.getFieldProps('confirmPassword')}
        type={passInput}
        placeholder={t('forms.senha')}
        isInvalid={
          formik.touched.confirmPassword && formik.errors.confirmPassword
        }
        isValid={
          formik.touched.confirmPassword && !formik.errors.confirmPassword
        }
      />
      <InputGroup.Text>
        <a onClick={() => changePassView()}>
          <i className={'fa ' + passIcon} />
        </a>
      </InputGroup.Text>

      <Form.Control.Feedback type='invalid'>
        {formik.touched.confirmPassword && formik.errors.confirmPassword
          ? formik.errors.confirmPassword
          : null}
      </Form.Control.Feedback>
    </InputGroup>
  )
}

export default PassWithEye
