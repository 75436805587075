import React, { useState } from 'react'
import { Col, Form, Alert, Row } from 'react-bootstrap'
import Button from '../../components/Button'
import ButtonFacebook from '../../components/ButtonFacebook'
import ButtonGoogle from '../../components/ButtonGoogle'
import ErrorFocus from '../../components/ErrorFocus'
import Link from 'next/link'
import PassWithEye from '../../components/PassWithEye'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { gql } from 'apollo-boost'
import { useTranslation } from '../../services/translation'
import { useMutation } from '@apollo/react-hooks'
import { useAuth } from '../../contexts/auth'
import { useBalance } from '../../contexts/balance'
import { deviceType, browserName } from 'react-device-detect'
import { useLocales } from '../../contexts/locales'

const DO_SIGNUP = gql`
  mutation createUser(
    $name: String!
    $email: String!
    $password: String!
    $sendConfirmationLink: Boolean!
    $currency: Currency!
  ) {
    createUser(
      name: $name
      email: $email
      password: $password
      sendConfirmationLink: $sendConfirmationLink
      currency: $currency
    ) {
      name
    }
  }
`

const RegisterForm = ({ onSuccess, sendConfirmationLink }) => {
  const { t } = useTranslation()
  const { currency } = useLocales()
  const [generalErrors, setGeneralErrors] = useState('')
  const [signUp] = useMutation(DO_SIGNUP)
  const { signIn, signInSocial } = useAuth()
  const { setBalance } = useBalance()
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      terms: false,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .max(100, t('forms.nomevali'))
        .required(t('forms.nomevali')),
      email: Yup.string()
        .email(t('forms.emailinvalido'))
        .required(t('forms.emailvali')),
      password: Yup.string().required(t('forms.senhavali')),
      confirmPassword: Yup.string()
        .required(t('forms.confirmasenhavali'))
        .oneOf([Yup.ref('password'), null], t('forms.confirmasenhaconfere')),
      terms: Yup.boolean().oneOf([true], t('forms.termosvali')),
    }),
    onSubmit: (values, { setSubmitting, setFieldError }) => {
      setGeneralErrors('')
      const { name, email, password } = values
      signUp({
        variables: {
          name,
          email,
          password,
          sendConfirmationLink,
          currency,
        },
      })
        .then(() => {
          signIn(email, password, false)
            .then(resultLogin => {
              const {
                balance,
                balancePending,
                balanceChargeback,
                balanceCollection,
              } = resultLogin.login.user
              setBalance(
                balance,
                balancePending,
                balanceChargeback,
                balanceCollection
              )
              onSuccess(false, resultLogin)
            })
            .catch(err => handleError(err, setFieldError))
            .finally(() => setSubmitting(false))
        })
        .catch(err => {
          handleError(err, setFieldError)
          setSubmitting(false)
        })
    },
  })
  const handleError = ({ networkError, graphQLErrors }, setFieldError) => {
    let gError = ''
    if (networkError || (!networkError && !graphQLErrors))
      gError = t('cadastro.failed')
    else {
      if (graphQLErrors.length) {
        graphQLErrors.map(({ state }) => {
          Object.entries(state).forEach(entry => {
            if (entry[0] === '') gError = entry[1][0]
            else setFieldError(entry[0], entry[1][0])
          })
        })
      }
    }
    setGeneralErrors(gError)
  }
  const doSocialLogin = (socialNetwork, userId, name, email, token) => {
    signInSocial(socialNetwork, userId, name, email, token, currency)
      .then(result => {
        const {
          balance,
          balancePending,
          balanceChargeback,
          balanceCollection,
        } = result.loginSocial.user
        setBalance(
          balance,
          balancePending,
          balanceChargeback,
          balanceCollection
        )
        onSuccess(true, result)
      })
      .catch(({ networkError, graphQLErrors }) => {
        let gError = ''
        if (networkError || (!networkError && !graphQLErrors))
          gError = t('login.failed')
        else {
          if (graphQLErrors.length) {
            graphQLErrors.map(({ state }) => {
              Object.entries(state).forEach(entry => {
                gError = entry[1][0]
              })
            })
          }
        }
        setGeneralErrors(gError)
      })
  }
  const responseFacebook = response => {
    if (!response.accessToken) return false
    doSocialLogin(
      'facebook',
      response.userID,
      response.name,
      response.email,
      response.accessToken
    )
  }
  const responseGoogle = response => {
    if (!response.profileObj) return false
    doSocialLogin(
      'google',
      response.googleId,
      response.profileObj.name,
      response.profileObj.email,
      response.tokenId
    )
  }
  return (
    <>
      {generalErrors && <Alert variant='danger'>{generalErrors}</Alert>}
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Row>
          <Form.Group as={Col} className='mb-3' controlId='formGridName'>
            <Form.Label>{t('forms.nome')}</Form.Label>
            <Form.Control
              {...formik.getFieldProps('name')}
              type='text'
              placeholder={t('forms.nome')}
              isInvalid={formik.touched.name && formik.errors.name}
              isValid={formik.touched.name && !formik.errors.name}
              maxLength='100'
            />
            <Form.Control.Feedback type='invalid'>
              {formik.touched.name && formik.errors.name
                ? formik.errors.name
                : null}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} className='mb-3' controlId='formGridEmail'>
            <Form.Label>{t('forms.email')}</Form.Label>
            <Form.Control
              {...formik.getFieldProps('email')}
              type='email'
              placeholder={t('forms.email')}
              isInvalid={formik.touched.email && formik.errors.email}
              isValid={formik.touched.email && !formik.errors.email}
              pattern='^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$'
            />
            <Form.Control.Feedback type='invalid'>
              {formik.touched.email && formik.errors.email
                ? formik.errors.email
                : null}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} className='mb-3' controlId='formGridPassword'>
            <Form.Label>{t('forms.senha')}</Form.Label>
            <PassWithEye formik={formik} />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group
            as={Col}
            className='mb-3'
            controlId='formGridConfirmPassword'
          >
            <Form.Label>{t('forms.confirmasenha')}</Form.Label>
            <PassWithEye formik={formik} confirmPass={true} />
          </Form.Group>
        </Row>
        <Form.Group className='mb-3'>
          <Form.Check
            type='checkbox'
            id='Check1'
            className='custom-control custom-checkbox'
          >
            <Form.Check.Input
              {...formik.getFieldProps('terms')}
              className='custom-control-input'
              type='checkbox'
              isInvalid={formik.touched.terms && formik.errors.terms}
            />
            <Form.Check.Label className='custom-control-label' htmlFor='Check1'>
              {t('forms.termo')}{' '}
              <Link href='/terms'>
                <a target='_blank'>{t('menubottom.item7')}</a>
              </Link>
            </Form.Check.Label>
            <Form.Control.Feedback type='invalid'>
              {formik.touched.terms && formik.errors.terms
                ? formik.errors.terms
                : null}
            </Form.Control.Feedback>
          </Form.Check>
        </Form.Group>
        <Button
          disabled={formik.isValidating || formik.isSubmitting}
          loading={formik.isValidating || formik.isSubmitting}
          label={t('botoes.cadastrar')}
        />
        <ErrorFocus formikprm={formik} />
      </Form>
      <div className='social-login'>
        {(deviceType === 'mobile' && browserName === 'Facebook') ||
        browserName === 'Chrome WebView' ? (
          <Alert variant='warning' className='small'>
            {t('forms.msgsocialloginwebview')}
          </Alert>
        ) : (
          <>
            <div>{t('forms.sociallogin')}</div>
            <ButtonFacebook callback={responseFacebook} />
            <ButtonGoogle callback={responseGoogle} />
          </>
        )}
      </div>
    </>
  )
}

export default RegisterForm
