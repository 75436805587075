import React, { useState } from 'react'
import GoogleLogin from 'react-google-login'
import { Spinner } from 'react-bootstrap'

const ButtonGoogle = ({ callback }) => {
  const [loading, setLoading] = useState(false)

  const handleButtonClick = (e, renderProps) => {
    renderProps.onClick(e)
    setLoading(true)
  }

  const onFailure = error => {
    setLoading(false)
    callback(error)
  }

  return (
    <GoogleLogin
      clientId='583723759235-ncvlehrldn2l46jl1av9pq45s32pt6k2.apps.googleusercontent.com'
      onSuccess={callback}
      onFailure={onFailure}
      cookiePolicy='single_host_origin'
      render={renderProps => (
        <a
          href='#'
          className='bt-google'
          onClick={e => handleButtonClick(e, renderProps)}
          disabled={renderProps.disabled}
        >
          <i className='fa fa-google' /> Google
          {loading && (
            <Spinner
              as='span'
              animation='border'
              size='sm'
              role='status'
              aria-hidden='true'
            />
          )}
        </a>
      )}
    />
  )
}

export default ButtonGoogle
