import React, { useState } from 'react'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { Spinner } from 'react-bootstrap'

const ButtonFacebook = ({ callback }) => {
  const [loading, setLoading] = useState(false)

  const handleButtonClick = (e, renderProps) => {
    renderProps.onClick(e)
    setLoading(true)
  }

  const callCallback = response => {
    if (!response.accessToken) {
      setLoading(false)
    }
    callback(response)
  }

  return (
    <FacebookLogin
      appId='523696041576385'
      fields='name,email,picture'
      callback={callCallback}
      disableMobileRedirect
      render={renderProps => (
        <a
          href='#'
          className='bt-facebook'
          onClick={e => handleButtonClick(e, renderProps)}
          disabled={renderProps.isDisabled}
        >
          <i className='fa fa-facebook' /> Facebook
          {loading && (
            <Spinner
              as='span'
              animation='border'
              size='sm'
              role='status'
              aria-hidden='true'
            />
          )}
        </a>
      )}
    />
  )
}

export default ButtonFacebook
