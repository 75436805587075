import { useEffect } from 'react'
import { scrollToElement } from '../../utils/scroll'

const ErrorFocus = ({ formikprm }) => {
  const { isSubmitting, isValidating, errors } = formikprm

  useEffect(() => {
    const keys = Object.keys(errors)
    if (keys.length > 0 && isSubmitting && !isValidating) {
      let errorElement = document.querySelector(`[name="${keys[0]}"]`)
      if (errorElement === null) {
        errorElement = document.querySelector(`[id="${keys[0]}"]`)
      }
      scrollToElement(errorElement)
    }
  }, [isSubmitting, isValidating, errors])

  return null
}

export default ErrorFocus
